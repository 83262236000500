import * as React from 'react'
import { DollarSign } from 'styled-icons/feather/DollarSign'
import { AlertTriangle } from 'styled-icons/feather/AlertTriangle'
import { MessageSquare } from 'styled-icons/feather/MessageSquare'
import { User } from 'styled-icons/feather/User'
import { Box, Column, Control, Field, Input, Section, Title } from 'rbx'
import Collapse, { Panel } from 'rc-collapse'
import { graphql } from 'gatsby'
import { css } from '@emotion/core'
import Page from 'components/Page'
import { ElevatedCardWrapper } from 'components/Card/Elevated'
import StyledContainer from 'components/Container'
import { PageHeader } from 'components/Header/index'
import IndexLayout from '../../layouts'
import { Faq } from '../../utils/types'

const IndexPage = ({ data }) => {
  const {
    allPrismicFaq: { edges }
  } = data

  const faqEdges = edges
  const faqs = [] as Faq[]
  faqEdges.forEach(({ node: { data } }) => faqs.push(data))

  const { questions } = faqs[0]

  return (
    <IndexLayout>
      <Page>
        <StyledContainer>
          <PageHeader>
            <Column.Group centered>
              <Column size="two-thirds">
                <Title size={5}>OrderSenta Support</Title>
                <Title size={3}>How can we help today?</Title>
                <p>Belay, command me whale, ye real breeze! How stormy. You love like a sea-dog. </p>
              </Column>
            </Column.Group>

            <Column.Group centered>
              <Column size="half">
                <Field>
                  <Control>
                    <Input type="text" placeholder="Search keywords, questions or topics..." value="" size="medium" />
                  </Control>
                </Field>
              </Column>
            </Column.Group>
          </PageHeader>

          <Title as="h4" size={5}>
            Popular categories
          </Title>
          <hr />
          <Column.Group multiline>
            <Column tablet={{ size: 'half' }} desktop={{ size: 'one-quarter' }}>
              <ElevatedCardWrapper>
                <Box>
                  <DollarSign size="4.8rem" />
                  <Title as="h4" size={6}>
                    Subscription & Pricing
                  </Title>
                </Box>
              </ElevatedCardWrapper>
            </Column>
            <Column tablet={{ size: 'half' }} desktop={{ size: 'one-quarter' }}>
              <ElevatedCardWrapper>
                <Box>
                  <User size="4.8rem" />
                  <Title as="h4" size={6}>
                    Account
                  </Title>
                </Box>
              </ElevatedCardWrapper>
            </Column>
            <Column tablet={{ size: 'half' }} desktop={{ size: 'one-quarter' }}>
              <ElevatedCardWrapper>
                <Box>
                  <AlertTriangle size="4.8rem" />
                  <Title as="h4" size={6}>
                    Troubleshooting
                  </Title>
                </Box>
              </ElevatedCardWrapper>
            </Column>
            <Column tablet={{ size: 'half' }} desktop={{ size: 'one-quarter' }}>
              <ElevatedCardWrapper>
                <Box>
                  <MessageSquare size="4.8rem" />
                  <Title as="h4" size={6}>
                    Live Chat
                  </Title>
                </Box>
              </ElevatedCardWrapper>
            </Column>
          </Column.Group>
        </StyledContainer>

        <Section>
          <StyledContainer>
            <Title as="h4" size={5}>
              Frequently Asked Questions
            </Title>
            <hr />

            <Collapse accordion>
              {questions.map(q => {
                const header = (
                  <Title
                    as="h5"
                    size={6}
                    css={css`
                      margin: 0;
                      line-height: 1;
                    `}
                  >
                    {q.question.text}
                  </Title>
                )

                return (
                  <Panel header={header} headerClass="my-header-class" key={q.question.text}>
                    <p>{q.answer.text}</p>
                  </Panel>
                )
              })}
            </Collapse>

            {/* <Box className=""> */}
            {/*  <Title as="h5" size={6}> */}
            {/*    How do I synchronize my orders from Amazon to Etsy? */}
            {/*  </Title> */}
            {/*  <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Aliquid atque eligendi ipsum iste natus vel.</p> */}
            {/* </Box> */}
          </StyledContainer>
        </Section>
      </Page>
    </IndexLayout>
  )
}

export default IndexPage

export const query = graphql`
  {
    allPrismicFaq {
      edges {
        node {
          id
          data {
            questions {
              answer {
                html
                text
              }
              question {
                html
                text
              }
            }
          }
        }
      }
    }
  }
`
