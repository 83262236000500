import styled from '@emotion/styled'
import { Section } from 'rbx'
import { dimensions } from '../../styles/variables'

export const PageHeader = styled(Section)`
  text-align: center;

  p {
    font-size: ${dimensions.fontSize.large}px;
  }
`
